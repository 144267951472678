.UserMenu {
    display: flex;
    top: 30px;
    right: 30px;
    font-size: 20px;
}

.badge-custom {
    margin-left: 10px; /* Расстояние от текста */
    padding: 2px 8px; /* Уменьшенные внутренние отступы */
    font-size: 0.8rem; /* Меньший размер текста */
    line-height: 1.2; /* Увеличенная высота строки */
    border-radius: 10px;
}

@keyframes blink {
    0% {
        color: red;
    }
    50% {
        color: black;
    }
    100% {
        color: red;
    }
}

.blink {
    animation: blink 1.5s infinite; /* 2s - медленное мигание */
}

.MenuItemDecor {
    text-decoration: none;
    color: black;
}