.Home > img {
    width: 20%;
    border-radius: 10px;
}

.Home > h1, h3 {
    color: #FFFFFF; /* Цвет для заголовков */
}

.Home > p {
    color: #B0B0B0; /* Цвет для параграфов */
}

.Home {
    margin-inline: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Выравнивание по левому краю */
    justify-content: left;
}
.Home > p > .p-header {
    text-align: center; /* Выравнивание по центру */
}
.Home h1 {
    align-self: center; /* Выравнивание по центру */
}

.Home h3,
.Home > .p-text {
    text-align: left; /* Явное выравнивание текста по левому краю */
}