.Menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding-top: 38px;
    padding-left: 50px;
    color: #ffffff;
}

.Menu a {
    text-decoration: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    padding: 5px 0;
    transition: color 0.3s ease;
}

.Menu a:hover {
    color: #bbbbbb;
}

.Menu a.active {
    color: #808080;
    font-weight: bold;
    font-family: Arial, serif;
}

.menu-category {
    font-weight: bold;
    margin-top: 20px;
    font-size: 1.1rem;
    color: #ffffff;
}
