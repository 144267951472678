.publisher-card {
    background-color: #202020;
    color: #fff;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
}

.publisher-card.hovered {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.publisher-card-content {
    padding: 16px;
}

.publisher-card-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
}

.publisher-card-details {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.publisher-card-writer,
.publisher-card-release-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    font-size: 14px;
}

.label {
    flex: 1;
    text-align: left;
    font-weight: bold;
}

.value {
    flex: 1;
    text-align: right;
}

.divider {
    margin: 8px 0;
    background-color: #FFFFFF;
}
