html, body, #root {
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
    height: 100%; /* Задаем высоту в 100% для всех элементов */
    margin: 0; /* Убираем внешние отступы */
    padding: 0; /* Убираем внутренние отступы */
    text-align: center; /* Центрируем текст по горизонтали */
    background-color: #151515; /* Устанавливаем темный фон */
    color: #ffffff; /* Устанавливаем белый цвет текста */
}
a {
    color: blue;
}

form {
    width: 300px;
    border: 1px solid;
    border-radius: 10px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 5px 5px 10px;
    text-align: left;
}

form > input, form > button {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px; /* Reduce the margin to make the form shorter */
}