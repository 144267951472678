.Layout {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 200px 1fr;
    height: 100vh;
}

header {
    grid-column: 1 / 3;
}

aside {
    grid-row: 2 / 3;
}

main {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    overflow: auto;
    padding: 20px;
}

footer {
    grid-column: 1 / 3;
}


/*.Layout, .Layout > * {*/
/*    border: 1px solid;*/
/*}*/