.game-detail {
    display: flex;
    background-color: #2D2D2D;
    border-radius: 10px;
    padding: 20px;
    color: #FFFFFF;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container {
    flex: 1;
    margin-right: 20px;
}

.image {
    width: 100%;
    border-radius: 10px;
}

.info-container {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 24px;
    margin: 0 0 10px 0;
}

.game-detail > .info-container > .date {
    font-size: 18px;
    color: #AAAAAA;
    margin: 0 0 20px 0;
}

.summary {
    font-size: 16px;
    line-height: 1.5;
}
