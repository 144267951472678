.game-card {
    background-color: #202020;
    color: #fff;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s;
    cursor: pointer;
}

.game-card:hover {
    transform: scale(1.05);
}

.game-card-image img {
    width: 100%;
    height: auto;
}

.game-card-content {
    padding: 16px;
}

.game-card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.game-card-details {
    font-size: 14px;
    margin-top: 8px;
}

.game-card-genres,
.game-card-release-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 5px;
    font-size: small;
}

.left-align {
    flex: 1;
    text-align: left;
}

.right-align {
    flex: 1;
    text-align: right;
}

.spacer {
    flex-grow: 1;
}

.divider-style {
}