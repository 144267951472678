.Header {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 50px;
}

.Header-inner {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.logo-link {
    text-decoration: none;
    color: inherit;
}

.logo {
    position: relative;
    font-size: 18px;
    font-weight: 900;
    line-height: 1;
    letter-spacing: 5px;
    color: #fff;
}

.UserMenu {
    margin-left: auto;
}
