.search-bar {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
}

.search-input-wrapper {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 50px;
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-icon {
    margin-right: 10px;
    color: #888888;
}

.search-input-wrapper input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 16px;
}

.clear-icon {
    margin-left: 10px;
    cursor: pointer;
    color: #888888;
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #1f1f1f;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
    max-height: 400px;
    overflow-y: auto;
}

.search-results h4 {
    margin: 10px;
    color: #ffffff;
    font-size: 14px;
}

.search-result {
    padding: 10px;
    color: #ffffff;
    cursor: pointer;
}

.search-result:hover {
    background-color: #333333;
}

.see-all-results {
    padding: 10px;
    text-align: center;
    color: #888888;
    cursor: pointer;
}

.see-all-results:hover {
    background-color: #333333;
    color: #ffffff;
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 768px) {
    .search-bar {
        width: 100%;
        margin: 10px 0;
    }

    .search-input-wrapper {
        padding: 10px;
    }

    .search-input-wrapper input {
        font-size: 14px;
    }
}
