.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
    padding: 16px;
    justify-content: center; /* Center the grid items */
}

.Category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.Category-name h1 {
    margin: 0;
    color: #ffffff;
}

.Category-button {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.Category-button .MuiButton-contained {
    background-color: #4caf50;
    color: #ffffff;
}

.Category-button .MuiButton-contained:hover {
    background-color: #45a049;
}

.Navigator {
    text-decoration: none;
    color: #B0B0B0;
}

.Navigator:hover {
    text-decoration: none;
    color: white;
}